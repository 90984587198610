<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('list_roles')" class="back-arrow">
			<h1>{{ editAction ? $t('roles.title_edit') : $t('roles.title_new') }}</h1>
		</div>
		<div class="form-section">
			<div class="input-group-tbf" v-bind:class="{has_error: $v.role.name.$error, complete: role.name != '' && !$v.role.name.$error, 'view-tooltip': activeTooltip == 'role_name' }">
				<div class="label-input">
					<label>{{ $t('roles.name') }}</label>
					<div class="info-input" @click="activeTooltip == 'role_name' ? activeTooltip = '' : activeTooltip = 'role_name'">
						<icon-circle-check class="icon-check" v-if="role.name != '' && !$v.role.name.$error" />
						<icon-info class="icon-info" v-else/>
					</div>
				</div>
				<div class="input-box">
					<div class="icon-right" v-if="role.name != ''" @click.stop="role.name = ''"><icon-plus class="icon-clear"/></div>
					<input type="text" :placeholder="$t('roles.name-placeholder')" class="input-text no-icon-left" v-model="role.name" ref="first_input">
				</div>
				<div class="tooltip-input">
					<div class="header">
						<div class="title">{{ $t('roles.name-tooltip-title') }}</div>
						<div class="close-tooltip" @click="activeTooltip = ''">
							<icon-plus class="icon-close"/>
						</div>
					</div>
					<div class="description">{{ $t('roles.name-tooltip-desc') }}</div>
				</div>
			</div>
		</div>
		<div class="input-box-with-checkboxes">
			<label class="label">{{ $t('roles.personal_objectives') }}</label>
			<div class="checkboxes-group" v-if="$auth.user().rights.view_roles">
				<label class="checkbox-tbf">
					{{ $t('roles.all_permisions') }}
					<input type="checkbox" v-model="check_own_rights" @change="CheckOwnRights">
					<span class="checkmark"></span>
				</label>							
			</div>
			<div class="checkboxes-group">
				<label class="checkbox-tbf">
					{{ $t('roles.edit') }}
					<input type="checkbox" value="1" v-model="role.edit_o" v-bind:disabled="!$auth.user().rights.view_roles">
					<span class="checkmark"></span>
				</label>							
			</div>
			<div class="checkboxes-group">
				<label class="checkbox-tbf">
					{{ $t('roles.delete') }}
					<input type="checkbox" value="1" v-model="role.delete_o" v-bind:disabled="!$auth.user().rights.view_roles">
					<span class="checkmark"></span>
				</label>							
			</div>
		</div>
		<div class="input-box-with-checkboxes">
			<label class="label">{{ $t('roles.other_objectives') }}</label>
			<div class="checkboxes-group" v-if="$auth.user().rights.view_roles">
				<label class="checkbox-tbf">
					{{ $t('roles.all_permisions') }}
					<input type="checkbox" v-model="check_all_rights" @click="CheckAllRights">
					<span class="checkmark"></span>
				</label>							
			</div>
			<div class="checkboxes-group">
				<label class="checkbox-tbf">
					{{ $t('roles.create') }}
					<input type="checkbox" value="1" v-model="role.create_a" v-bind:disabled="!$auth.user().rights.view_roles">
					<span class="checkmark"></span>
				</label>							
			</div>
			<div class="checkboxes-group">
				<label class="checkbox-tbf">
					{{ $t('roles.edit') }}
					<input type="checkbox" value="1" v-model="role.edit_a" v-bind:disabled="!$auth.user().rights.view_roles">
					<span class="checkmark"></span>
				</label>							
			</div>
			<div class="checkboxes-group">
				<label class="checkbox-tbf">
					{{ $t('roles.view') }}
					<input type="checkbox" value="1" v-model="role.view_a" v-bind:disabled="!$auth.user().rights.view_roles">
					<span class="checkmark"></span>
				</label>							
			</div>
			<div class="checkboxes-group">
				<label class="checkbox-tbf">
					{{ $t('roles.delete') }}
					<input type="checkbox" value="1" v-model="role.delete_a" v-bind:disabled="!$auth.user().rights.view_roles">
					<span class="checkmark"></span>
				</label>							
			</div>
		</div>
		<div class="submit-form" v-if="role.name != 'admin' && role.name != 'manager' && role.name != 'employee' && role.name != 'observer'">
			<button class="button-tbf-blue button-action" id="updateSendBtn" @click="saveModifications">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ editAction ? $t('roles.edit_button') : $t('roles.save_button') }}
			</button>
		</div>
	</div>
</template>


<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"

	export default {
		data() {
			return {
				loaded: true,
				editAction: false,
				check_all_rights: false,
				check_own_rights: false,
				activeTooltip: '',
				role: {
					name: '',
					description: '',
					create_a: false,
					delete_o: false,
					delete_a: false,
					edit_o: false,
					edit_a: false,
					view_o: false,
					view_a: false,
					rights: {
						delete: true,
						edit: true
					}
				},
				errorData: [],
				subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                },
			};
		},
		props:{
			model: Object
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
		},
		validations: {
			role: {
				name: {required},
			}
		},
		async mounted() {
			if(Object.keys(this.model).length === 0 && this.model.constructor === Object){
				this.editAction = false
			}else{
				this.role = this.model
				this.editAction = true
			}

			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal.confirm_close = true
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
            });

			setTimeout(()=>{
            	this.$refs.first_input.focus()
			}, 250)
		},
		methods: {
			closeModal(){
				this.subModalShow = true
				this.show_subm_modal.confirm_close = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_confirm_close').addClass('active');
					}, 200);
                }, 0);
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					if(this.editAction){
						this.updateRole(e)
					}else{
						this.storeRole(e)
					}
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			storeRole(e){
				axios.post('/roles/store', {
					name: this.role.name,
					description: this.role.description,
					create_a: this.role.create_a,
					delete_o: this.role.delete_o,
					delete_a: this.role.delete_a,
					edit_o: this.role.edit_o,
					edit_a: this.role.edit_a,
					view_o: this.role.view_o,
					view_a: this.role.view_a
				}).then(({data}) => {
					$(e.target).attr('disabled', false)
					this.$emit('change_slide', 'list_roles')
				}).catch(error => {
					$(e.target).attr('disabled', false)
				});
			},
			updateRole(e){
				axios.patch('/roles/' + this.role.id, {
					name: this.role.name,
					description: this.role.description,
					create_a: this.role.create_a,
					delete_o: this.role.delete_o,
					delete_a: this.role.delete_a,
					edit_o: this.role.edit_o,
					edit_a: this.role.edit_a,
					view_o: this.role.view_o,
					view_a: this.role.view_a
				}).then(({data}) => {
					$(e.target).attr('disabled', false)
					this.$emit('change_slide', 'list_roles')
				}).catch(error => {
					$(e.target).attr('disabled', false)
				});
			},
			autosize: function(event){
				var textarea = event.target;
				setTimeout(function(){
					textarea.style.height = "auto";
					textarea.style.height = textarea.scrollHeight + "px";
				},0);
			},
			CheckOwnRights: function() {
				this.role.delete_o = this.check_own_rights;
				this.role.edit_o = this.check_own_rights;
			},
			CheckAllRights: function() {
				this.role.create_a = !this.check_all_rights;
				this.role.delete_a = !this.check_all_rights;
				this.role.edit_a = !this.check_all_rights;
				this.role.view_a = !this.check_all_rights;
			},
			checkInputs(){
				var notEmpty = false

				if(this.role.name != ''
				){
					notEmpty = true
				}

				return notEmpty
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			}
		}
	};
</script>